#app {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: hidden;
}

.content {
  padding-top: 5%;
  padding-left: 33px;
  width: 100%;
}

.mainApp {
  display: flex;
}

.slidecontainer {
  width: 100%; /* Width of the outside container */
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
/* override cesium navigation widget */
.compass {
  bottom: 60px;
  right: 20px;
  top: auto;
}
/* override cesium navigation widget */
.navigation_open {
  position: relative;
}
.distance-legend {
  background-color: transparent !important;
  border: none !important;
  right: 10px !important;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}

svg:not(:root) {
  padding: 0px;
  margin: 0px;
}

p {
  color: inherit;
}
label[for] {
  line-height: inherit;
}
.tbody2d {
  color: inherit;
}

/* OPENLAYERS */

.attributions {
  width: 30%;
}

.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
}
.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.ol-tooltip-static {
  background-color: #00bef3;
  color: black;
}
.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}

.ol-tooltip-static:before {
  border-top-color: #00bef3;
}

.ol-scale-line {
  align-self: center;
  align-content: center;
}

.ol-scale-line-inner {
  color: #eee;
  border-style: solid;
  border-width: 1px;
  border-color: #eee;
  border-top: 0px;
  display: flex;
  justify-content: center;
  font-size: 0.75em;
}

.ol-rotate {
  display: block;
}

/* Cesium Geocoder */

.custom-cesium-viewer-geocoderContainer {
  position: absolute;
  top: 68px;
  z-index: 1;
}

.cesium-viewer-geocoderContainer .cesium-geocoder-input {
  border: solid 1px #ccc !important;
  border-radius: 3px !important;
  background-color: #e0e0e0 !important;
  color: #222 !important;
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) !important;
  padding: 2px;
}
.cesium-viewer-geocoderContainer .cesium-geocoder-input:focus {
  border-color: #ccc !important;
  padding: 2px;
}
.cesium-viewer-geocoderContainer:hover .cesium-geocoder-input {
  border-color: #ccc !important;
  padding: 2px;
}
.cesium-viewer-geocoderContainer .search-results {
  background-color: #fff !important;
  color: black !important;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.8) !important;
  transition: max-height 0.3s ease-in !important;
}

.cesium-viewer-geocoderContainer .cesium-geocoder-input {
  padding: 2px !important;
  width: 250px !important;
}

/* OL Geocoder */
.ol-geocoder.gcd-gl-container {
  position: absolute;
  z-index: 12;
  top: 70px;
  left: 65px;
  transform: translateX(0);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1);
}

.home.left-open .ol-geocoder.gcd-gl-container {
  transform: translateX(320px);
}

.home.edit-open .ol-geocoder.gcd-gl-container {
  transform: translateX(0px);
}

.ol-geocoder .gcd-gl-control {
  transition: width 0s 0.5s;
}

.ol-geocoder .gcd-gl-expanded {
  transition: none;
}

.ol-geocoder .gcd-gl-btn {
  visibility: hidden;
}

.ol-geocoder .gcd-gl-input {
  top: 0;
  left: 33px;
  height: 30px;
  border-radius: 3px;
  background-color: #e0e0e0;
  transform: scaleX(0);
  transform-origin: left center;
  opacity: 0;
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1);
}

.ol-geocoder .gcd-gl-input:focus {
  border: 1px solid #ccc;
  box-shadow: none;
}

.ol-geocoder .gcd-gl-expanded .gcd-gl-input {
  opacity: 1;
  transform: scaleX(1);
}

.ol-geocoder .gcd-gl-reset {
  height: 27px;
  line-height: 1.1;
}

.ol-geocoder ul.gcd-gl-result {
  left: 33px;
  border-radius: 3px;
}

.ol-geocoder ul.gcd-gl-result > li {
  line-height: 1.1;
}

.ol-geocoder ul.gcd-gl-result > li:hover {
  background-color: #e0e0e0;
}

.ol-geocoder ul.gcd-gl-result > li:nth-child(odd) {
  background-color: #e0f7fd;
  background-color: white;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

:focus {
  outline: 0px;
}

.MuiChip-label {
  padding-left: 3px;
  padding-right: 3px;
}

@media print {
  @page {
    size: "A4";
    margin: 0mm;
    margin-top: 10mm;
    margin-bottom: 10mm;
  }
  @page :first {
    size: "A4";
    margin: 0mm;
    margin-bottom: 10mm;
  }
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
  body::-webkit-scrollbar {
    display: none;
  }
  body {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  img {
    page-break-inside: avoid;
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h1,
  h2,
  h3 {
    page-break-inside: avoid;
    page-break-after: avoid;
  }
  h1::after,
  h2::after,
  h3::after {
    content: "";
    display: block;
    height: 100px;
    margin-bottom: -100px;
  }
  table {
    page-break-after: auto;
    border-collapse: collapse;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  td {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  th {
    page-break-inside: avoid;
    page-break-after: avoid;
  }
  thead {
    page-break-after: avoid !important;
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
  .pageBreakAfter {
    page-break-after: always;
  }
  .pageBreakBefore {
    page-break-before: always;
  }
  .avoidBreakBefore {
    page-break-before: avoid;
  }
  .avoidBreakInside {
    page-break-inside: avoid;
  }
  .displayNonOnPrint {
    display: none;
  }
  svg {
    page-break-inside: avoid;
    display: block;
  }
}
